<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 gx-xl-8">
    <div class="col-xxl-4">
      <DailyTasks widget-classes="card-xxl-stretch mb-xl-3"></DailyTasks>
    </div>
    <div class="col-xxl-8">
      <!--begin::Input-->

      <!--end::Input-->
    </div>
  </div>

  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DailyTasks from "@/components/lists/DailyTasks.vue";

export default defineComponent({
  name: "dashboard",
  components: { DailyTasks },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;

    const route = useRoute();
    const routeName = computed(() => route.name);
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });

    return {
      currentUser,

      routeName,
    };
  },
});
</script>
