
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { FbmOrdersFromApiRequest } from "../../core/types/FbmOrderTypes";

export default defineComponent({
  name: "daily-task-list",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const store = useStore();
    const syncButton = ref<HTMLButtonElement | null>(null);
    const syncFbmButton = ref<HTMLButtonElement | null>(null);
    const getFBMOrdersButton = ref<HTMLButtonElement | null>(null);
    const isDataReady = ref(false);
    const isFBMSyncDataReady = ref(false);
    const isFbmOrderDataReady = ref(false);

    const syncFBMOrderShippingStatusReponse = computed(() => {
      return store.getters.fbmOrderShippingStatusResponse;
    });
    const fbaInventoryResponse = computed(() => {
      return store.getters.fbaInventoryResponse;
    });
    const fbmOrdersFromApiRequest = ref<FbmOrdersFromApiRequest>({
      fromDate: "",
    });
    const fbmOrdersFromApiResponse = computed(() => {
      return store.getters.ordersFromApiResponse;
    });

    onMounted(() => {
      fbmOrdersFromApiRequest.value.fromDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
    });

    function syncFba() {
      store
        .dispatch(Actions.SYNC_FBA_INVENTORY)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (syncButton.value) {
        // eslint-disable-next-line
        syncButton.value!.disabled = true;
        // Activate indicator
        syncButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    function syncFbmOrderShippingStatus() {
      store
        .dispatch(Actions.SYNC_FBM_ORDERS_SHIPPING_STATUS)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (syncFbmButton.value) {
        // eslint-disable-next-line
        syncFbmButton.value!.disabled = true;
        // Activate indicator
        syncFbmButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    function getFBMOrders() {
      store
        .dispatch(
          Actions.GET_FBM_ORDERS_FROM_DATE_API,
          fbmOrdersFromApiRequest.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving the orders",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (getFBMOrdersButton.value) {
        // eslint-disable-next-line
        getFBMOrdersButton.value!.disabled = true;
        // Activate indicator
        getFBMOrdersButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    watch(fbaInventoryResponse, () => {
      isDataReady.value = true;
      //Deactivate indicator
      syncButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      syncButton.value!.disabled = false;
    });

    watch(syncFBMOrderShippingStatusReponse, () => {
      isFBMSyncDataReady.value = true;
      //Deactivate indicator
      syncFbmButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      syncFbmButton.value!.disabled = false;
    });

    watch(fbmOrdersFromApiResponse, () => {
      isFbmOrderDataReady.value = true;
      //Deactivate indicator
      getFBMOrdersButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      getFBMOrdersButton.value!.disabled = false;
    });

    return {
      store,
      isDataReady,
      isFBMSyncDataReady,
      isFbmOrderDataReady,
      fbmOrdersFromApiRequest,
      fbmOrdersFromApiResponse,
      fbaInventoryResponse,
      syncButton,
      syncFbmButton,
      getFBMOrdersButton,
      syncFBMOrderShippingStatusReponse,
      syncFba,
      getFBMOrders,
      syncFbmOrderShippingStatus,
    };
  },
});
