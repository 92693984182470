
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DailyTasks from "@/components/lists/DailyTasks.vue";

export default defineComponent({
  name: "dashboard",
  components: { DailyTasks },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;

    const route = useRoute();
    const routeName = computed(() => route.name);
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });

    return {
      currentUser,

      routeName,
    };
  },
});
