<template>
  <!--begin::List Widget 3-->
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header border-0 bg-secondary">
      <h3 class="card-title fw-bold text-dark">Daily Tasks</h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-2">
      <!--begin::Sync FBM Orders Shipping Status Item-->
      <div class="mb-2 d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-800 fw-bold fs-6">
            Sync FBM Orders Shipping Status</span
          >
        </div>
        <button
          tabindex="3"
          type="submit"
          ref="syncFbmButton"
          id="syncFbmBtn"
          class="btn btn-primary"
          @click="syncFbmOrderShippingStatus()"
        >
          <span class="indicator-label"> Sync </span>

          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
      <div v-if="isFBMSyncDataReady" class="mb-1 d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-success fw-bold fs-6">Success</span>
        </div>
      </div>
      <div v-if="isFBMSyncDataReady" class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-600 fs-6"
            >Total FBM Orders Updated:
            {{ syncFBMOrderShippingStatusReponse.totalSyncedOrders }}</span
          >
        </div>
      </div>
      <!--end:Sync FBM Orders Shipping Status Item-->
      <!--begin::Sync FBA Inventory Item-->
      <div class="mb-2 d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-800 fw-bold fs-6">Sync FBA Inventory</span>
        </div>
        <button
          tabindex="3"
          type="submit"
          ref="syncButton"
          id="syncFbaBtn"
          class="btn btn-primary"
          @click="syncFba()"
        >
          <span class="indicator-label"> Sync </span>

          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
      <div v-if="isDataReady" class="mb-1 d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-success fw-bold fs-6">Success</span>
        </div>
      </div>
      <div v-if="isDataReady" class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-600 fs-6"
            >Total FBA Products:
            {{ fbaInventoryResponse.totalFbaProducts }}</span
          >
        </div>
      </div>
      <div v-if="isDataReady" class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-600 fs-6">Low Inventory:</span>
        </div>
      </div>
      <div v-if="isDataReady" class="d-flex align-items-center">
        <div
          class="flex-grow-1"
          v-for="item in fbaInventoryResponse.lowInventoryProducts"
          :key="item.sku"
        >
          {{ item.sku }}, Qty: {{ item.stockQuantity }}
        </div>
      </div>
      <div v-if="isDataReady" class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-600 fs-6"
            >Sent Email: {{ fbaInventoryResponse.sendEmail }}</span
          >
        </div>
      </div>
      <!--end:Sync FBA Inventory Item-->
      <!--begin::Import FBM Orders Item-->
      <div class="mb-2 d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-800 fw-bold fs-6">Import FBM Orders</span>
        </div>
        <div>
          <!--begin::Label-->
          <label class="fs-6 fw-bold mb-2">From Date</label>
          <!--end::Label-->
          <el-date-picker
            name="fbmOrderfromDate"
            placeholder="Select from date"
            value-format="YYYY-MM-DD"
            v-model="fbmOrdersFromApiRequest.fromDate"
          />
          <button
            tabindex="3"
            type="submit"
            ref="getFBMOrdersButton"
            id="getFBMOrdersBtn"
            class="btn btn-primary"
            @click="getFBMOrders()"
          >
            <span class="indicator-label"> Import </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
      <div v-if="isFbmOrderDataReady" class="mb-1 d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-success fw-bold fs-6">Success</span>
        </div>
      </div>
      <div v-if="isFbmOrderDataReady" class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="text-gray-600 fs-6"
            >Total Orders: {{ fbmOrdersFromApiResponse.totalOrders }}</span
          >
        </div>
      </div>
      <!--end:Import FBM Order Item-->
    </div>
    <!--end::Body-->
  </div>
  <!--end:List Widget 3-->
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { FbmOrdersFromApiRequest } from "../../core/types/FbmOrderTypes";

export default defineComponent({
  name: "daily-task-list",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const store = useStore();
    const syncButton = ref<HTMLButtonElement | null>(null);
    const syncFbmButton = ref<HTMLButtonElement | null>(null);
    const getFBMOrdersButton = ref<HTMLButtonElement | null>(null);
    const isDataReady = ref(false);
    const isFBMSyncDataReady = ref(false);
    const isFbmOrderDataReady = ref(false);

    const syncFBMOrderShippingStatusReponse = computed(() => {
      return store.getters.fbmOrderShippingStatusResponse;
    });
    const fbaInventoryResponse = computed(() => {
      return store.getters.fbaInventoryResponse;
    });
    const fbmOrdersFromApiRequest = ref<FbmOrdersFromApiRequest>({
      fromDate: "",
    });
    const fbmOrdersFromApiResponse = computed(() => {
      return store.getters.ordersFromApiResponse;
    });

    onMounted(() => {
      fbmOrdersFromApiRequest.value.fromDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
    });

    function syncFba() {
      store
        .dispatch(Actions.SYNC_FBA_INVENTORY)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (syncButton.value) {
        // eslint-disable-next-line
        syncButton.value!.disabled = true;
        // Activate indicator
        syncButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    function syncFbmOrderShippingStatus() {
      store
        .dispatch(Actions.SYNC_FBM_ORDERS_SHIPPING_STATUS)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (syncFbmButton.value) {
        // eslint-disable-next-line
        syncFbmButton.value!.disabled = true;
        // Activate indicator
        syncFbmButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    function getFBMOrders() {
      store
        .dispatch(
          Actions.GET_FBM_ORDERS_FROM_DATE_API,
          fbmOrdersFromApiRequest.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving the orders",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (getFBMOrdersButton.value) {
        // eslint-disable-next-line
        getFBMOrdersButton.value!.disabled = true;
        // Activate indicator
        getFBMOrdersButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    watch(fbaInventoryResponse, () => {
      isDataReady.value = true;
      //Deactivate indicator
      syncButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      syncButton.value!.disabled = false;
    });

    watch(syncFBMOrderShippingStatusReponse, () => {
      isFBMSyncDataReady.value = true;
      //Deactivate indicator
      syncFbmButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      syncFbmButton.value!.disabled = false;
    });

    watch(fbmOrdersFromApiResponse, () => {
      isFbmOrderDataReady.value = true;
      //Deactivate indicator
      getFBMOrdersButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      getFBMOrdersButton.value!.disabled = false;
    });

    return {
      store,
      isDataReady,
      isFBMSyncDataReady,
      isFbmOrderDataReady,
      fbmOrdersFromApiRequest,
      fbmOrdersFromApiResponse,
      fbaInventoryResponse,
      syncButton,
      syncFbmButton,
      getFBMOrdersButton,
      syncFBMOrderShippingStatusReponse,
      syncFba,
      getFBMOrders,
      syncFbmOrderShippingStatus,
    };
  },
});
</script>
